<template>
    
  <div
    class="menu"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 消息通知 -->
      <el-tab-pane label="消息通知" name="messageInfo">
        <div>
          <el-select placeholder="消息类型" v-model="pageInfo.messageType">
            <el-option
              v-for="item in messageTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <TimeQuantum
            style="margin-right: 10px"
            @selectFinish="topTime"
            section="近7天"
            @sectionFinish="sectionSure"
          ></TimeQuantum>
          <el-input placeholder="请输入" v-model="pageInfo.keyword"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="search">立即查询</el-button>
          <el-button type="primary" @click="allread">全部已读</el-button>
          <!-- <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >新增</el-button
          > -->
          <!-- 表格 -->
          <el-table
            :data="tableData"
            border
            cell-style="text-align:center"
            header-cell-style="text-align:center;background:#EBEEF5"
            style="width: 100%; margin-top: 40px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed
            ></el-table-column>
            <el-table-column
              prop="messageTitle"
              label="消息标题"
              width="width"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="messageContent"
              label="消息正文"
              width="width"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="messageType" label="消息类型" width="width">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.messageType == '公告'"
                  style="color: #3cb371"
                  >{{ scope.row.messageType }}</span
                >
                <span
                  v-if="scope.row.messageType == '警告'"
                  style="color: #b22222"
                  >{{ scope.row.messageType }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间" width="width">
            </el-table-column>
            <!-- <el-table-column label="账号状态" width="width">
              <template slot-scope="scoped">
                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0 20px;
                  "
                >
                  <el-switch
                    style="display: block"
                    v-model="scoped.row.status"
                    active-text="发布"
                    inactive-text="保存"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                    @change="changeStatus(scoped.row.id, scoped.row.status)"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column prop="prop" label="操作" width="width">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="font-size: 15px"
                  class="el-icon-document"
                  @click="getDetail(scope.row.id)"
                  >详情</el-button
                >
                <!-- <el-button
                  type="text"
                  style="font-size: 15px"
                  @click="changeMessage(scope.row.id)"
                  >修改</el-button
                >
                <el-button
                  type="text"
                  style="font-size: 15px"
                  @click="deleteMessage(scope.row.id)"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页区域 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 30, 40]"
            :total="total"
            :page-size="pageInfo.pageSize"
            :current-page="pageInfo.current"
          >
          </el-pagination>
          <!-- 详情弹框 -->
          <el-dialog
            width="1140px"
            :title="title"
            :visible.sync="detailBox"
            :close-on-click-modal="false"
          >
            <el-form
              ref="formInline"
              :inline="true"
              :model="detailtableData"
              class="demo-form-inline"
              label-position="left"
              label-width="80px"
            >
              <el-form-item
                label="消息标题"
                prop="messageTitle"
                style="width: 700px"
              >
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.messageTitle"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息正文"
                prop="messageContent"
                style="width: 700px"
              >
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.messageContent"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息类型"
                prop="messageType"
                style="width: 800px"
              >
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.messageType"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item label="发布时间" prop="createTime">
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.createTime"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item label="账号状态" prop="status">
                <div style="width: 450px">
                  <el-switch
                    disabled
                    style="display: block"
                    v-model="detailtableData.status"
                    active-text="发布"
                    inactive-text="保存"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                  >
                  </el-switch>
                </div>
              </el-form-item>

              <div style="text-align: right; margin-top: 30px">
                <el-button @click="detailBox = false">取 消</el-button>
                <el-button type="primary" @click="detailBox = false"
                  >确认</el-button
                >
              </div>
            </el-form>
          </el-dialog>
          <!-- 新增弹框 -->
          <el-dialog width="960px" :title="title" :visible.sync="addBox">
            <el-form
              ref="formInline"
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-position="left"
              label-width="120px"
            >
              <el-form-item
                label="消息标题"
                prop="messageTitle"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="formInline.messageTitle"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息正文"
                prop="messageContent"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="formInline.messageContent"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息类型"
                prop="messageType"
                style="width: 800px"
              >
                <el-select
                  v-model="formInline.messageType"
                  placeholder="请选择"
                  style="margin-top: 0"
                >
                  <el-option
                    v-for="item in typelist"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="帐号状态" prop="status">
                <el-switch
                  style="display: block"
                  v-model="formInline.status"
                  active-text="发布"
                  inactive-text="保存"
                  active-value="1"
                  inactive-value="0"
                >
                </el-switch>
              </el-form-item>
              <el-form-item
                label="定时发布时间"
                prop="status"
                v-if="formInline.status == 0"
              >
                <div class="block" style="width: 700px">
                  <el-date-picker
                    v-model="formInline.waitingReleaseTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <div style="text-align: right; margin-top: 30px">
                <el-button @click="addBox = false">取 消</el-button>
                <el-button type="primary" @click="messageconfirm"
                  >确定</el-button
                >
              </div>
            </el-form>
          </el-dialog>
          <!-- 编辑弹框 -->
          <el-dialog
            width="1140px"
            title="修改消息"
            :visible.sync="changeBox"
            :close-on-click-modal="false"
          >
            <el-form
              ref="formInline"
              :inline="true"
              :model="detailtableDatas"
              class="demo-form-inline"
              label-position="left"
              label-width="80px"
            >
              <el-form-item
                label="消息标题"
                prop="messageTitle"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="detailtableDatas.messageTitle"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息正文"
                prop="messageContent"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="detailtableDatas.messageContent"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息类型"
                prop="messageType"
                style="width: 800px"
              >
                <el-select
                  placeholder=""
                  v-model="detailtableDatas.messageType"
                  style="margin: 0"
                >
                  <el-option
                    v-for="item in messageTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账号状态" prop="status">
                <div style="width: 450px">
                  <el-switch
                    style="display: block"
                    v-model="detailtableDatas.status"
                    active-text="发布"
                    inactive-text="保存"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                  >
                  </el-switch>
                </div>
              </el-form-item>

              <div style="text-align: right; margin-top: 30px">
                <el-button @click="changeBox = false">取 消</el-button>
                <el-button type="primary" @click="changeConfirm"
                  >确认</el-button
                >
              </div>
            </el-form>
          </el-dialog>
        </div>
      </el-tab-pane>
      <!-- 修改密码 -->
      <el-tab-pane label="修改密码" name="changePassword">
        <el-form
          ref="form"
          :model="user"
          :rules="rules"
          label-width="80px"
          class="password"
        >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input
              v-model="user.oldPassword"
              placeholder="请输入旧密码"
              type="password"
            />
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model="user.newPassword"
              placeholder="请输入新密码"
              type="password"
            />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input
              v-model="user.confirmPassword"
              placeholder="请确认密码"
              type="password"
            />
          </el-form-item>
          <el-form-item>
            <div>
              <el-button type="success" size="mini" @click="submit"
                >确认更改</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="子账号" name="childAccount"></el-tab-pane>
      <!-- 个人信息 -->
      <el-tab-pane label="个人信息" name="personalInfor">
        <div class="per" style="margin-top: 75px">
          <el-form
            :rules="personalRules"
            v-loading="dialogLoading"
            :model="personalList"
            label-width="120px"
            style="padding: 0 50px"
          >
            <el-form-item label="用户昵称">
              <el-input class="uu" v-model="personalList.userName" @blur="editNickname"></el-input
              ><el-button type="text" icon="el-icon-edit"></el-button>
            </el-form-item>
            <el-form-item label="用户手机" prop="phone">
              <el-input class="uu" v-model="personalList.userPhone" @blur="editphoneNum"></el-input
              ><el-button type="text" icon="el-icon-edit"></el-button>
            </el-form-item>
            <el-form-item label="用户邮箱">
              <span>{{ personalList.userMail }}</span>
            </el-form-item>
            <el-form-item label="微信绑定">
              <div v-if="personalList.isBinding == 0">
                 <el-button
                   size="mini"
                   type="primary"
                   @click="unboundClick"
                   icon="el-icon-connection"
                   style="margin-left: 4px; margin-right: 8px"
                >未绑定</el-button>
                <span>推送每日店铺数据
                  <el-switch
                    v-model="personalList.isPushData"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                  >
                  </el-switch>
                </span>
              </div>
              <div v-if="personalList.isBinding == 1">
                <el-button type="primary" size="mini" @click="isboundClick">已绑定</el-button>
              </div>
            </el-form-item>
            <el-form-item label="最后登录">
              <span>{{ personalList.lastLoginTime }}</span>
            </el-form-item>
            <el-form-item label="登录次数">
              <span>{{ personalList.loginSum }}</span>
            </el-form-item>
            <el-form-item label="注册时间">
              <span>{{ personalList.createTime }}</span>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      width="15%"
      title="打开微信扫描二维码绑定"
      :visible.sync="wechatDialogVisible"
    > 
    <div>
      <div class="shade el-icon-warning-outline" v-if="shade">
        <el-button class="refresh" @click="getTicket" type="text">点击刷新</el-button>
      </div>
      <img :src="QrCode" alt="" width="100%">
    </div>
    </el-dialog>
  </div>
</template>

<script>
import TimeQuantum from "@/components/TimeQuantum";
import {
  updateUserPwd,
  listPage,
  detail,
  update,
  messageadd,
  deleteMessage,
  getPersonInfo,
  read,
  updatestatus,
  editUserInfo,     // 编辑用户信息
  getBindingTicket, // 获取【绑定】二维码
  whetherScan,      // 【绑定】二维码是否扫描
} from "@/api/personaldata.js";
export default {
  components: {
    TimeQuantum,
  },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    // 验证手机 
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    };
    return {
      wechatDialogVisible: false,
      QrCode: "",
      shade: false,
      time: null,
      ticket: "",
      personalList: {},
      title: "",
      messageTypeList: [
        { value: "0", label: "警告", },
        { value: "1", label: "公告", },
      ],
      typelist: [
        { value: "0", label: "警告", },
        { value: "1", label: "公告", },
      ],
      formInline: {
        messageType: "",
        messageTitle: "",
        messageContent: "",
        status: "",
        waitingReleaseTime: "",
      },
      list: {
        messageType: "",
      },
      detailtableData: {},
      detailtableDatas: {
        messageType: "",
        messageTitle: "",
        messageContent: "",
        status: "",
      },
      changeBox: false,
      addBox: false,
      detailBox: false,
      pageInfo: {
        keyword:'',
        messageType:'',
        pageSize: "",
        current: "1",
        beginTime: "",
        endTime: "",
      },
      total: 0,
      list: {
        status: "",
      },
      ids: [],
      idList: [],
      tableData: [],
      activeName: "messageInfo",
      loading: false,
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      },
      // 表单校验
      rules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
      },
      // personalRules: {
      //   phone: [
      //     { message: '请输入手机号', trigger: 'blur' },
      //     { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: 'blur' }
      //   ]
      // }
    };
  },
  created() {
    // this.getactiveName();
    this.getData();
    this.getPersonInfo();
  },
  methods: {
    // getactiveName(){
    //   this.activeName = this.$route.params.activeName
    // },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = {
            oldPassword: this.user.oldPassword,
            newPassword: this.user.newPassword,
          };
          updateUserPwd(data).then((response) => {
            this.msgSuccess("修改成功");
          });
        }
      });
    },
    handleClick(tab, event) {
      if (tab.index == 2) {
        this.$router.push("/childAccount");
      }
    },
    //页面初始化
    async getData() {
      this.loading = true;
      try {
        const res = await listPage(this.pageInfo);
        if ((Object.is(res.code), 200)) {
          this.loading = false;
          this.tableData = res.data.values;
          this.total = res.data.pageInfo.total;
          this.tableData.map(function (val) {
            if (val.messageType === 1) {
              val.messageType = "公告";
            } else {
              val.messageType = "警告";
            }
          });
        } else {
          this.$message.error(res.message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //查询
    search(){
      this.getData();
    },
    //状态改变
    changeStatus(id, status) {
      this.loading = true;
      let data = {
        id: id,
        messageType: status,
      };
      updatestatus(data).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },

    //详情
    getDetail(id) {
      detail(id).then((res) => {
        this.title = "消息详情";
        this.detailBox = true;
        this.loading = false;
        if (res.data.code == 200) {
          this.detailtableData = res.data.data;
          console.log(this.detailtableData.messageType);
          if (this.detailtableData.messageType == 1) {
            this.detailtableData.messageType = "公告";
          } else if (this.detailtableData.messageType == 0) {
            this.detailtableData.messageType = "警告";
          }
        } else {
          this.loading = false;
          this.detailBox = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    //修改
    changeMessage(id) {
      detail(id).then((res) => {
        this.changeBox = true;
        if (res.data.code == 200) {
          this.detailtableDatas = res.data.data;
          // if (this.detailtableDatas.messageType == 1) {
          //   this.list.messageType = "公告";
          // } else if (this.detailtableDatas.messageType == 0) {
          //   this.list.messageType = "警告";
          // }
        } else {
          this.loading = false;
          this.changeBox = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    //修改确认
    changeConfirm() {
      update(this.detailtableDatas).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
          this.changeBox = false;
        } else {
          this.loading = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    //新增
    add() {
      this.addBox = true;
      this.formInline = {};
    },
    //新增确认
    async messageconfirm() {
      try {
        const res = await messageadd(this.formInline);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.addBox = false;
            this.$message.success(res.data.message);
            this.getData();
          } else {
            this.$message.error(res.data.message);
            this.addBox = false;
          }
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //删除
    deleteMessage(id) {
      this.$confirm("删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMessage(id).then((res) => {
          if (res.data.code == 200) {
            this.getData();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      });
    },
    //批量读消息
    async allread() {
      try {
        let data = {
          ids: this.idList,
        };
        const res = await read(data);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // table 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.forEach((data) => {
        ids.push(data.id);
      });
      this.idList = ids;
    },
    handleSizeChange(newSize) {
      this.pageInfo.pageSize = newSize;
    },
    handleCurrentChange(newCurrent) {
      this.pageInfo.current = newCurrent;
    },
    topTime(val) {
      this.pageInfo.beginTime = val.value[0];
      this.pageInfo.endTime = val.value[1];
    },
    //个人资料
    async getPersonInfo() {
      try {
        const res = await getPersonInfo(this.formInline);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.personalList = res.data.data;
            console.log(this.personalList);
          } else {
            this.$message.error(res.data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 编辑用户昵称
    editNickname() {
      if(!this.personalList.userName) {
        this.$message.error('用户昵称不能为空');
        return false;
      }
      let data = {
        userName: this.personalList.userName
      }
      editUserInfo(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.personalList.userName = res.data.data.userName;
          this.$message.success(res.data.message);
        }
      })
    },
    // 编辑用户手机
    editphoneNum() {
      if(!this.personalList.userPhone) {
        this.$message.error('用户手机号不能为空');
        return false;
      }
      let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if(!(phoneReg.test(this.personalList.userPhone))) {
        this.$message.error('请输入合法的手机号');
        return false;
      }
      let data = {
        userPhone: this.personalList.userPhone
      }
      editUserInfo(data)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.personalList.userPhone = res.data.data.userPhone;
           this.$message.success(res.data.message);
         }
       })
    },
    // 未绑定事件
    unboundClick() {
      getBindingTicket()
      .then(res=> {
        console.log(res, 'kkkkkkkkkkk');
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.QrCode = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${res.data.data}`;
          this.time = new Date().getTime();
          this.ticket = res.data.data;
          this.shade = false;
          this.whetherScan()
        }
      })
      this.wechatDialogVisible = true;
    },
    whetherScan() {
      var current = new Date().getTime();
      const num = current - this.time;
      if(num>1000*60*3 || this.shade){
        this.shade = true;
        this.time = new Date().getTime();
        return;
      }
      whetherScan({ticket:this.ticket}).then(res=>{
        if(res.data.code == 500){
          this.$message.error(res.data.message)
          return
        }
        var token = window.sessionStorage.getItem('token')||''
        if(token) return 
        if(res.data.code != 200){
          this.shade = true
          return
        }
        if(res.data.code == 200){
          const timer = setTimeout(()=>{
            this.whetherScan()
            clearTimeout(this.timer)
          },3000)
        }
      })
    },
    isboundClick() {
      let data = {
        isBinding: 0
      }
      editUserInfo(data)
      .then(res=> {
        console.log(res, 'lpppppppppppp');
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.personalList.isBinding = res.data.data.isBinding;
        }
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.password {
  width: 500px;
  margin-top: 100px;
  margin-left: 50px;
}
.el-pagination {
  padding-top: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.el-select {
  margin-right: 20px;
  margin-top: 30px;
  width: 220px;
}
.el-input {
  width: 220px;
  margin-right: 20px;
}
::v-deep .uu .el-input__inner {
  border: 0px;
}
.shade{
  background:rgba(0,0,0,.9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  color: orange;
  text-align:center;
  line-height: 250px;
  font-size: 100px;
  .refresh{
    position: absolute;
    bottom: 20px;
    color: white;
    font-size: 20px;
    left: 84px;
  }
}
</style>